<template>
  <PagedTag />
</template>

<script>
import PagedTag from './page/_page'
export default {
  components: {
    PagedTag,
  },
}
</script>
