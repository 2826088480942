





































import { Component } from 'vue-property-decorator'
import Posts from '~components/content/Posts.vue'
import SearchPagination from '~components/content/widgets/pagination/SearchPagination.vue'
import { ListingPage, ListingPageVariables } from '~base/mixins/listings'
import {
  VueMetaProperty,
  VueMetaInfo,
  ApolloOperationVariables,
  ListingPageType,
} from '~base/common/types'
import Layout2 from '~components/content/layouts/Layout-2.vue'
import Layout4 from '~components/content/layouts/Layout-4.vue'

@Component<ListingPage & TagsPage>({
  components: {
    Posts,
    SearchPagination,
  },

  head(): VueMetaInfo {
    return {
      title: this.title,
      meta: this.meta,
      link: this.link,
    }
  },

  apollo: {
    search: {
      query: require('~base/graphql/queries/FetchPostsForTag.gql'),
      variables(): ApolloOperationVariables {
        return this.variables
      },
      prefetch({ route }: any): ApolloOperationVariables {
        return {
          first: this.perPage,
          page: parseInt(route.params.page || '0'),
          tags: [route.params.tag],
        }
      },
      result({ data }) {
        this.gotResult(data)
      },
    },
  },
})
export default class TagsPage extends ListingPage {
  private extendQueryVariables = {}
  protected type: ListingPageType = 'tags'
  protected settingsKey = this.$route.params.tag

  get sidebar() {
    return this.pageSettings.sidebar || 'tags'
  }

  get layouts() {
    return (
      this.pageSettings.layouts || [
        { component: Layout2, items: 2 },
        { component: Layout4, items: 2 },
      ]
    )
  }

  created() {
    super.created()
  }

  mounted() {
    this.$store.dispatch('ads/pageChanged', {
      pageType: 'index',
      category: this.$route.params.tag,
    })
    this.$store.dispatch('tracking/trackSearchPage', this.$route.params.tag)
  }

  loadMoreData() {
    const { endCursor } = this.search.pageInfo
    if (endCursor) {
      this.after = endCursor
      this.nextPage()
    }
  }

  get variables(): ListingPageVariables {
    return {
      ...super.variables,
      tags: [this.$route.params.tag],
      ...this.extendQueryVariables,
    }
  }

  get title(): string {
    const prop = `seo.title.tags.${this.$route.params.tag}`
    let title = this.$root.$t(prop).toString()
    if (prop === title) {
      title = `#${this.$route.params.tag} - ${this.$root.$t(
        'seo.title.tags.default'
      )}`
    }

    return title
  }

  get meta(): VueMetaProperty[] {
    const prop = `seo.description.tags.${this.$route.params.tag}`
    // related to vue-i18n issue #184 https://github.com/kazupon/vue-i18n/issues/184
    let description = this.$root.$t(prop).toString()
    if (prop === description) {
      description = this.$root
        .$t('seo.description.tags.default', [this.$route.params.tag])
        .toString()
    }

    return [
      ...super.meta,
      { hid: 'description', name: 'description', content: description },
      { hid: 'og:title', name: 'og:title', content: this.title },
      { hid: 'og:description', name: 'og:description', content: description },
    ]
  }

  get titleComponent(): object | undefined {
    return super.titleComponent
  }

  onTitleAction(e: { [Identifier: string]: string }) {
    this.extendQueryVariables = e
  }

  get linkPrefix(): string {
    return `/tags/${this.$route.params.tag}`
  }
}
